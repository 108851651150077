import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./Global/globalSlice";
import vehicleSizesSlice from "./VehicleSizes/vehicleSizeSlice";

export const store = configureStore({
    reducer: {
        globalReducer: globalSlice,
        vehicleSizesReducer: vehicleSizesSlice,
    },
});
