// import { cachedFetch } from "@/lib/apiCache";
// import { GET } from "@/lib/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// export const fetchExtensions = createAsyncThunk("extensions/get", async () => {
//     const response = await GET("sierra/get-extensions");
//     return response;
// });

const globalSlice = createSlice({
    name: "global",
    initialState: {
        template_settings: {
            data: {},
            loading: false,
        },
        global_settings: {
            data: {},
            loading: false,
        },
    },
    reducers: {
        setTemplateSettings: (state, action) => {
            state.template_settings.data = action.payload;
        },
        setGlobalSettings: (state, action) => {
            state.global_settings.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        // builder
        //     .addCase(fetchExtensions.pending, (state) => {
        //         state.extensions.status = "loading";
        //     })
        //     .addCase(fetchExtensions.fulfilled, (state, action) => {
        //         state.extensions.status = action.payload.status;
        //         state.extensions.data = action.payload.data;
        //         state.extensions.message = action.payload.message;
        //     })
        //     .addCase(fetchExtensions.rejected, (state, action) => {
        //         state.extensions.status = "failed";
        //         state.extensions.message = action.error.message;
        //     })
    },
});

export default globalSlice.reducer;

export const { setGlobalSettings, setTemplateSettings } = globalSlice.actions;
