import { createSlice } from "@reduxjs/toolkit";

const vehicleSizesSlice = createSlice({
    name: "vehicleSizes",
    initialState: {
        vehicleSizes: {
            data: {},
        },
    },
    reducers: {
        setVehicleSizes: (state, action) => {
            state.vehicleSizes.data = action.payload;
        },
    },
});

export default vehicleSizesSlice.reducer;

export const { setVehicleSizes } = vehicleSizesSlice.actions;
